import React from "react";
import Layout from "../../components/layout";

const ASurvivorsChecklist = () => {
  return (
    <Layout>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1 className="block  text-center text-customBlue font-semibold tracking-wide uppercase">
              A Survivor’s Checklist
            </h1>
            <p className="block  text-center text-gray-600 font-semibold tracking-wide mb-10">
              What to do when a loved one dies
            </p>
            <h3 className="block  text-customBlue font-semibold tracking-wide ">
              Immediately Following The Death
            </h3>
            <p>Locate loved one’s important documents:</p>
            <ul className="list-disc">
              <li>Will/Trust</li>
              <li>Birth Certificate</li>
              <li>Social Security Card</li>
              <li>Marriage License</li>
              <li>Military Discharge Papers (DD214)</li>
              <li>Deed to Burial Property</li>
              <li>Copy of Funeral Prearrangements</li>
              <li>Life Insurance Papers</li>
            </ul>
            <h3 className="block  text-customBlue font-semibold tracking-wide">
              Within One Month Of The Death
            </h3>
            <ul className="list-disc">
              <li>File claims with life insurance companies.</li>
              <li>
                Contact the Social Security Administration and other government
                offices that were making payments to the decedent. Inquire about
                eligibility for spousal benefits.
              </li>
              <li>Notify the registrar of voters.</li>
              <li>Cancel deceased’s prescriptions.</li>
              <li>
                Cancel all social media accounts and retain pictures if need be.
              </li>
              <li>
                Contact the department of motor vehicles to cancel driver’s
                license and transfer titles to registered vehicles.
              </li>
              <li>
                If the deceased was a veteran, inquire about benefits from the
                VA.
              </li>
              <li>
                Contact the deceased’s employer. Inquire about company benefits
                such as 401K, pension…
              </li>
              <li>
                Check for life insurance benefits available through credit cards
                or loans.
              </li>
              <li>File any outstanding claims for health insurance.</li>
              <li>Obtain deceased’s outstanding bills.</li>
              <li>Advice all creditors in writing that the death occurred.</li>
              <li>Update beneficiaries on your life insurance policies</li>

              <li>
                Locate paperwork for necessity for the settlement of their
                estate:
                <ul className="list-disc">
                  <li>Trust information</li>
                  <li>Real estate deeds and titles</li>
                  <li>Stock certificates</li>
                  <li>Loan paperwork</li>
                  <li>Bank accounts</li>
                  <li>Retirement accounts</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ASurvivorsChecklist;
